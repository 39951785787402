import App, { AppContext } from "next/app";
import React, { useEffect } from "react";
import "../styles/globals.css";
import type { AppProps } from "next/app";
import Head from "next/head";
import { AppBar, BDSProvider, colors } from "@barracuda-internal/bds-core";
import { SessionProvider, getSession, getCsrfToken } from "next-auth/react";
import { Session } from "next-auth";
import { AuthButton } from "../lib/components/AuthButton";
import { CsrfTokenContextProvider } from "../lib/components/CsrfTokenContext";
import { LanguageChooser } from "../lib/components/LanguageChooser";
import BarracudaLogo from "@barracuda-internal/bds-core/dist/Logos/Barracuda/LogoPrimaryRev";
import { Cookies, CookiesProvider } from "react-cookie";
import { log } from "@pam/common/base-logger";
import { addMessagesLoader } from "@barracuda-internal/messages-lib";

// suppress useLayoutEffect warnings when running outside a browser (i.e. server logs)
if (typeof window === "undefined") React.useLayoutEffect = React.useEffect;

export interface CustomPageProps {
  csrfToken: string;
  session: Session;
  signOutUrl: string;
  hideAppBar: boolean;
  currentLocale: string;
}

export interface CustomAppProps extends AppProps {
  pageProps: CustomPageProps;
}

void addMessagesLoader(function commonMessageLoader(locale: string) {
  log.debug(`Fetching common lids for locale ${locale}`);
  if (locale === "en_US") {
    return import(
      /*
  		webpackMode: "eager",
  		webpackChunkName: "common-messages-[request]",
  		webpackInclude: /en_US/
  		*/
      `@barracuda-internal/messages-webpack-loader?{key:'common',exportsOf:'lib/lids/common.mjs'}!@barracuda-internal/commondata/locale/${locale}/messages.txt`
    );
  }

  return import(
    /*
		webpackMode: "lazy",
		webpackChunkName: "common-messages-[request]",
		webpackExclude: /en_US/
		*/
    `@barracuda-internal/messages-webpack-loader?{key:'common',exportsOf:'lib/lids/common.mjs'}!@barracuda-internal/commondata/locale/${locale}/messages.txt`
  );
});

MyApp.getInitialProps = async (appContext: AppContext) => {
  const appProps = await App.getInitialProps(appContext);
  const { ctx } = appContext;

  // our signin redirect page should not have an app bar
  const hideAppBar = ctx.pathname === "/auth/login";

  // extract only this cookie so we don't leak other data
  const currentLocale = new Cookies(ctx.req?.headers.cookie).get<string>("CLOUD_LOCALE") || "en_US";

  return {
    ...appProps,
    pageProps: {
      ...appProps.pageProps,
      currentLocale,
      session: await getSession(ctx),
      csrfToken: await getCsrfToken(ctx),
      signOutUrl: `/api/auth/signout?callbackUrl=${encodeURIComponent(`${process.env.LOGIN_APP_BASE_URL}/logout`)}`,
      hideAppBar,
    } as CustomPageProps,
  };
};

function MyApp({ Component, pageProps }: CustomAppProps) {
  useEffect(() => {
    const jssStyles = globalThis.document.querySelector("#jss-server-side");
    if (jssStyles) {
      jssStyles.remove();
    }
  }, []);

  return (
    <>
      <Head>
        <meta name="viewport" content="initial-scale=1, viewport-fit=cover" />
      </Head>
      <CsrfTokenContextProvider value={pageProps.csrfToken}>
        <CookiesProvider cookies={new Cookies({ CLOUD_LOCALE: pageProps.currentLocale })}>
          <SessionProvider session={pageProps.session} refetchInterval={0}>
            <BDSProvider useBdsTheme={true}>
              {!pageProps.hideAppBar && (
                <AppBar
                  position="relative"
                  elevation={0}
                  style={{
                    background: colors.deepOcean800,
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    height: "55px",
                  }}
                >
                  <BarracudaLogo height={37} style={{ margin: "0 16px" }} />
                  <div style={{ flex: 10 }} />
                  <LanguageChooser />
                  <AuthButton signOutUrl={pageProps.signOutUrl} />
                </AppBar>
              )}
              <div id="content">
                <Component {...pageProps} />
              </div>
            </BDSProvider>
          </SessionProvider>
        </CookiesProvider>
      </CsrfTokenContextProvider>
    </>
  );
}

export default MyApp;
